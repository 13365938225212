<template>
  <div class="login-page">

    <section class="p-relative z-2">
      <div class="row">
        <div class="col-6 md-down:col-12 bg-white content-center" style="min-height: 100vh">

          <div class="text-center py-5">
            <img :src="require('../../../assets/logo-word.svg')" class="w-contain d-inline-block" alt="" style="max-width: 250px;">
            <small class="text-muted d-block strong">Dashboard</small>
          </div>

          <div class="w-100" style="max-width: 600px;">

            <p>Enter the email address you used when you joined and we’ll send you instructions to reset your password.</p>
            <p>For security reasons, we do NOT store your password. So rest assured that we will never send your password via email.</p>

            <mega-input class="mt-5" placeholder="email@mail.ru" label="Email" v-model="email" :valid="validEmail"/>

            <div class="d-flex">
              <mega-button class="px-4 btn-primary w-100" :loading="loading" @click="submit">Send Reset Instructions</mega-button>
            </div>
          </div>

          <div class="text-center py-6">
            <small class="text-muted">ARROUND ARR Manager v 2.5.5</small>
          </div>
        </div>
      </div>
    </section>

    <bg-particles :count="60"/>
  </div>
</template>

<script>
  import BgParticles from './components/bg-particles'

  export default {
    name: 'Recovery',
    data(){
      return {
        email: this.$route.query.email || '',
        password: '',
        remember: true,
        loading: false
      }
    },
    computed: {
      validEmail() {
        let valid = true;
        let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;

        this.$check(this.email)
          .then((a) => a.length < 3, () => {valid = false})
          .then((a) => !mailFormat.test(a.toLowerCase()), () => {valid = false});

        return valid
      }
    },
    methods: {

      submit: function() {

        if(!this.email) {
          this.$alert.danger('Email is required');
          return false;
        }

        console.log(this.validEmail)

        if(this.validEmail) {

          this.loading = true;

          this.$api.v2.post('/auth/email/recoveryEmail', {email: this.email})
            .then(() => {
              this.$router.push('/login');
              this.loading = false;
            })
            .catch(() => {
              this.$alert.danger('Email is not found');
              this.loading = false;
            });
        } else this.$alert.danger('Email is not correct');
      }
    },
    components: {
      BgParticles
    }
  }
</script>
