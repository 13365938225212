var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-page" },
    [
      _c("section", { staticClass: "p-relative z-2" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-6 md-down:col-12 bg-white content-center",
              staticStyle: { "min-height": "100vh" }
            },
            [
              _c("div", { staticClass: "text-center py-5" }, [
                _c("img", {
                  staticClass: "w-contain d-inline-block",
                  staticStyle: { "max-width": "250px" },
                  attrs: {
                    src: require("../../../assets/logo-word.svg"),
                    alt: ""
                  }
                }),
                _vm._v(" "),
                _c("small", { staticClass: "text-muted d-block strong" }, [
                  _vm._v("Dashboard")
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w-100", staticStyle: { "max-width": "600px" } },
                [
                  _c("p", [
                    _vm._v(
                      "Enter the email address you used when you joined and we’ll send you instructions to reset your password."
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "For security reasons, we do NOT store your password. So rest assured that we will never send your password via email."
                    )
                  ]),
                  _vm._v(" "),
                  _c("mega-input", {
                    staticClass: "mt-5",
                    attrs: {
                      placeholder: "email@mail.ru",
                      label: "Email",
                      valid: _vm.validEmail
                    },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c(
                        "mega-button",
                        {
                          staticClass: "px-4 btn-primary w-100",
                          attrs: { loading: _vm.loading },
                          on: { click: _vm.submit }
                        },
                        [_vm._v("Send Reset Instructions")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm._m(0)
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("bg-particles", { attrs: { count: 60 } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center py-6" }, [
      _c("small", { staticClass: "text-muted" }, [
        _vm._v("ARROUND ARR Manager v 2.5.5")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }